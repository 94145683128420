define("@1024pix/pix-ui/components/pix-selectable-tag", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-selectable-tag {{if this.isChecked ' pix-selectable-tag--checked'}}">
    <input
      type="checkbox"
      id={{@id}}
      onChange={{this.toggleIsChecked}}
      checked={{this.isChecked}}
      ...attributes
    />
    <label for={{@id}}>{{@label}}</label>
  </div>
  */
  {
    "id": "lCTtYu3p",
    "block": "[[[10,0],[15,0,[29,[\"pix-selectable-tag \",[52,[30,0,[\"isChecked\"]],\" pix-selectable-tag--checked\"]]]],[12],[1,\"\\n  \"],[11,\"input\"],[24,4,\"checkbox\"],[16,1,[30,1]],[16,\"onChange\",[30,0,[\"toggleIsChecked\"]]],[16,\"checked\",[30,0,[\"isChecked\"]]],[17,2],[12],[13],[1,\"\\n  \"],[10,\"label\"],[15,\"for\",[30,1]],[12],[1,[30,3]],[13],[1,\"\\n\"],[13]],[\"@id\",\"&attrs\",\"@label\"],false,[\"if\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-selectable-tag.hbs",
    "isStrictMode": false
  });

  let PixSelectableTag = (_class = class PixSelectableTag extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isChecked", _descriptor, this);
    }

    toggleIsChecked() {
      this.isChecked = !this.isChecked;
      return this.args.onChange(this.isChecked);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isChecked", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.checked;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleIsChecked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsChecked"), _class.prototype)), _class);
  _exports.default = PixSelectableTag;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixSelectableTag);
});