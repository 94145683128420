define("@1024pix/pix-ui/components/pix-sidebar", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@1024pix/pix-ui/utils/unique-id"], function (_exports, _component, _templateFactory, _component2, _object, _uniqueId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="pix-sidebar__overlay {{unless @showSidebar ' pix-sidebar__overlay--hidden'}}"
    {{on "click" this.closeAction}}
    {{trap-focus @showSidebar}}
    {{on-escape-action @onClose}}
  >
    <div
      class="pix-sidebar {{unless @showSidebar ' pix-sidebar--hidden'}}"
      role="dialog"
      aria-labelledby="sidebar-title--{{this.id}}"
      aria-describedby="sidebar-content--{{this.id}}"
      aria-modal="true"
      ...attributes
    >
      <div class="pix-sidebar__header">
        <h1 id="sidebar-title--{{this.id}}" class="pix-sidebar__title">{{@title}}</h1>
        <PixIconButton
          @icon="xmark"
          @triggerAction={{@onClose}}
          @ariaLabel="Fermer"
          @size="small"
          @withBackground={{true}}
          class="pix-sidebar__close-button"
        />
      </div>
      <div id="sidebar-content--{{this.id}}" class="pix-sidebar__content">
        {{yield to="content"}}
      </div>
      <div class="pix-sidebar__footer">
        {{yield to="footer"}}
      </div>
    </div>
  </div>
  */
  {
    "id": "SrYHr30w",
    "block": "[[[11,0],[16,0,[29,[\"pix-sidebar__overlay \",[52,[51,[30,1]],\" pix-sidebar__overlay--hidden\"]]]],[4,[38,1],[\"click\",[30,0,[\"closeAction\"]]],null],[4,[38,2],[[30,1]],null],[4,[38,3],[[30,2]],null],[12],[1,\"\\n  \"],[11,0],[16,0,[29,[\"pix-sidebar \",[52,[51,[30,1]],\" pix-sidebar--hidden\"]]]],[24,\"role\",\"dialog\"],[16,\"aria-labelledby\",[29,[\"sidebar-title--\",[30,0,[\"id\"]]]]],[16,\"aria-describedby\",[29,[\"sidebar-content--\",[30,0,[\"id\"]]]]],[24,\"aria-modal\",\"true\"],[17,3],[12],[1,\"\\n    \"],[10,0],[14,0,\"pix-sidebar__header\"],[12],[1,\"\\n      \"],[10,\"h1\"],[15,1,[29,[\"sidebar-title--\",[30,0,[\"id\"]]]]],[14,0,\"pix-sidebar__title\"],[12],[1,[30,4]],[13],[1,\"\\n      \"],[8,[39,4],[[24,0,\"pix-sidebar__close-button\"]],[[\"@icon\",\"@triggerAction\",\"@ariaLabel\",\"@size\",\"@withBackground\"],[\"xmark\",[30,2],\"Fermer\",\"small\",true]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[15,1,[29,[\"sidebar-content--\",[30,0,[\"id\"]]]]],[14,0,\"pix-sidebar__content\"],[12],[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"pix-sidebar__footer\"],[12],[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@showSidebar\",\"@onClose\",\"&attrs\",\"@title\",\"&content\",\"&footer\"],false,[\"unless\",\"on\",\"trap-focus\",\"on-escape-action\",\"pix-icon-button\",\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-sidebar.hbs",
    "isStrictMode": false
  });

  let PixSidebar = (_class = class PixSidebar extends _component2.default {
    constructor() {
      super(...arguments);

      if (!this.args.title) {
        throw new Error('ERROR in PixSidebar component: @title argument is required.');
      }
    }

    closeAction(event) {
      if (this.args.onClose && this.isClickOnOverlay(event)) {
        this.args.onClose(event);
      }
    }

    isClickOnOverlay(event) {
      return event.target.classList.contains('pix-sidebar__overlay');
    }

    get id() {
      return (0, _uniqueId.default)();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "closeAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeAction"), _class.prototype)), _class);
  _exports.default = PixSidebar;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixSidebar);
});