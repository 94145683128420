define("@1024pix/pix-ui/components/pix-textarea", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-textarea">
  
    {{#if this.label}}
      <label for={{@id}} class="pix-textarea__label">{{this.label}}</label>
    {{/if}}
  
    <Textarea
      id={{@id}}
      @value={{@value}}
      maxlength={{if @maxlength @maxlength}}
      class="{{if @errorMessage 'pix-textarea--error'}}"
      ...attributes
    />
    {{#if @maxlength}}
      <p>{{this.textLengthIndicator}} / {{@maxlength}}</p>
    {{/if}}
  
    {{#if @errorMessage}}
      <label for={{this.id}} class="pix-textarea__error-message">{{@errorMessage}}</label>
    {{/if}}
  </div>
  */
  {
    "id": "bJfpSopl",
    "block": "[[[10,0],[14,0,\"pix-textarea\"],[12],[1,\"\\n\\n\"],[41,[30,0,[\"label\"]],[[[1,\"    \"],[10,\"label\"],[15,\"for\",[30,1]],[14,0,\"pix-textarea__label\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,1],[[16,1,[30,1]],[16,\"maxlength\",[52,[30,2],[30,2]]],[16,0,[29,[[52,[30,3],\"pix-textarea--error\"]]]],[17,4]],[[\"@value\"],[[30,5]]],null],[1,\"\"],[41,[30,2],[[[1,\"    \"],[10,2],[12],[1,[30,0,[\"textLengthIndicator\"]]],[1,\" / \"],[1,[30,2]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"label\"],[15,\"for\",[30,0,[\"id\"]]],[14,0,\"pix-textarea__error-message\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@id\",\"@maxlength\",\"@errorMessage\",\"&attrs\",\"@value\"],false,[\"if\",\"textarea\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-textarea.hbs",
    "isStrictMode": false
  });

  class PixTextarea extends _component2.default {
    get textLengthIndicator() {
      return this.args.value ? this.args.value.length : 0;
    }

    get label() {
      var _this$args$label, _this$args$id;

      const labelIsDefined = (_this$args$label = this.args.label) === null || _this$args$label === void 0 ? void 0 : _this$args$label.trim();
      const idIsNotDefined = !((_this$args$id = this.args.id) !== null && _this$args$id !== void 0 && _this$args$id.trim());

      if (labelIsDefined && idIsNotDefined) {
        throw new Error('ERROR in PixTextarea component, @id param is necessary when giving @label');
      }

      return this.args.label || null;
    }

  }

  _exports.default = PixTextarea;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixTextarea);
});