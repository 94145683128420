define("@1024pix/ember-testing-library/index", ["exports", "@ember/test-helpers", "@testing-library/dom"], function (_exports, _testHelpers, _dom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    getScreen: true,
    within: true,
    visit: true,
    render: true,
    clickByName: true,
    clickByText: true,
    fillByLabel: true,
    selectByLabelAndOption: true,
    selectOptionInRadioGroup: true
  };
  _exports.clickByName = clickByName;
  _exports.clickByText = clickByText;
  _exports.fillByLabel = fillByLabel;
  _exports.getScreen = getScreen;
  _exports.render = render;
  _exports.selectByLabelAndOption = selectByLabelAndOption;
  _exports.selectOptionInRadioGroup = selectOptionInRadioGroup;
  _exports.visit = visit;
  _exports.within = within;
  Object.keys(_dom).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _dom[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _dom[key];
      }
    });
  });

  /**
   * Wrap the EmberJS container with DOM testing library.
   * https://testing-library.com/docs/queries/about
   *
   * @returns The EmberJS container wrapped by the DOM testing library.
   */
  function getScreen() {
    return (0, _dom.within)((0, _testHelpers.getRootElement)());
  }
  /**
   * Wrap the Testing-Library within function.
   *
   * @param element: DOM tree.
   * @returns The inner DOM of the provided element.
   */


  function within(element) {
    return (0, _dom.within)(element);
  }
  /**
   * Visit the given URL in the EmberJS testing server and returns DOM testing library helpers.
   *
   * @param {string} url URL of the page to visit.
   * @returns DOM testing library helpers for the given page URL.
   */


  async function visit(url) {
    await (0, _testHelpers.visit)(url);
    return getScreen();
  }
  /**
   * Renders a EmberJS component template and returns DOM testing library helpers.
   *
   * @param {string} template EmberJS component template.
   * @returns DOM testing library helpers for the given component.
   */


  async function render(template) {
    await (0, _testHelpers.render)(template);
    return getScreen();
  }
  /**
   * Click on a DOM element selected by a name.
   *
   * @param {string} name Name of the clickable DOM element.
   * @param {*} options Testing library getByRole options.
   * @returns Promise of the click.
   */


  function clickByName(name) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const {
      getByRole
    } = getScreen();
    const element = getByRole(/button|link|radio|checkbox/, { ...options,
      name
    });
    return (0, _testHelpers.click)(element);
  }
  /**
   * Click on a DOM element selected by a text.
   *
   * @param {string} text Text of the clickable DOM element.
   * @param {*} options Testing library getByText options.
   * @returns Promise of the click.
   */


  function clickByText(text, options) {
    const {
      getByText
    } = getScreen();
    const element = getByText(text, options);
    return (0, _testHelpers.click)(element);
  }
  /**
   * Fill with the given value a DOM element selected by a label.
   *
   * @param {string} label Label linked to the control to fill.
   * @param {*} options Testing library getByLabelText options.
   * @returns Promise of the filling.
   */


  function fillByLabel(label, value, options) {
    const {
      getByLabelText
    } = getScreen();
    const element = getByLabelText(label, options);
    return (0, _testHelpers.fillIn)(element, value);
  }
  /**
   * Select an option in a dropdown with DOM element selected by a label.
   *
   * @param {string} label Label linked to the dropdown.
   * @param {string} option Option value.
   * @param {*} options Testing library getByRole options.
   * @returns Promise of the filling.
   */


  function selectByLabelAndOption(label, option, options) {
    const {
      getByRole
    } = getScreen();
    const element = getByRole('combobox', { ...options,
      name: label
    });
    return (0, _testHelpers.fillIn)(element, option);
  }
  /**
   * Select a radio button part of a radio group identified by its label .
   *
   * @param {string} label Label linked to the radio group.
   * @param {string} option The radio button label.
   * @param {*} options Testing library getByRole options.
   * @returns Promise of the filling.
   */


  function selectOptionInRadioGroup(label, option, options) {
    const {
      getByRole
    } = getScreen();
    const parent = getByRole('radiogroup', { ...options,
      name: label
    });
    const element = (0, _dom.within)(parent).getByRole('radio', {
      name: option
    });
    return (0, _testHelpers.click)(element);
  }
});