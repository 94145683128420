define("ember-dayjs/helpers/dayjs-months", ["exports", "ember-dayjs/helpers/base-helper"], function (_exports, _baseHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DayjsMonths extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      this.dayjs.extend('localeData');
      return this.dayjs.self.months();
    }

  }

  _exports.default = DayjsMonths;
});