define("@1024pix/pix-ui/components/pix-filterable-and-searchable-select", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <div class="{{if @screenReaderOnly 'screen-reader-only'}}">
      <label class="pix-filterable-and-searchable-select__label" for={{this.id}}>
        {{#if @requiredText}}
          <abbr class="mandatory-mark" title="{{@requiredText}}" aria-hidden="true">*</abbr>
        {{/if}}
        {{@label}}
      </label>
      {{#if @subLabel}}
        <span class="pix-select__sub-label">{{@subLabel}}</span>
      {{/if}}
    </div>
    <div
      class="pix-filterable-and-searchable-select{{if
          @errorMessage
          ' pix-filterable-and-searchable-select--error'
        }}"
    >
      <PixMultiSelect
        @values={{this.selectedCategories}}
        @label={{@categoriesLabel}}
        @options={{this.categories}}
        @onChange={{this.selectCategories}}
        @screenReaderOnly={{true}}
        @className="pix-filterable-and-searchable-select__pix-multi-select"
      >
        <:placeholder>{{this.categoriesPlaceholder}}</:placeholder>
        <:default as |option|>{{option.label}}</:default>
      </PixMultiSelect>
      <PixSelect
        @id={{this.id}}
        @placeholder={{@placeholder}}
        @value={{@value}}
        @options={{this.selectableOptions}}
        @onChange={{@onChange}}
        @isSearchable={{@isSearchable}}
        @searchLabel={{@searchLabel}}
        @screenReaderOnly={{true}}
        @className="pix-filterable-and-searchable-select__pix-select"
      />
    </div>
    {{#if @errorMessage}}
      <p class="pix-filterable-and-searchable-select__error-message">{{@errorMessage}}</p>
    {{/if}}
  </div>
  */
  {
    "id": "r+tF75rI",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[52,[30,1],\"screen-reader-only\"]]]],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"pix-filterable-and-searchable-select__label\"],[15,\"for\",[30,0,[\"id\"]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,\"abbr\"],[14,0,\"mandatory-mark\"],[15,\"title\",[29,[[30,2]]]],[14,\"aria-hidden\",\"true\"],[12],[1,\"*\"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[10,1],[14,0,\"pix-select__sub-label\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"pix-filterable-and-searchable-select\",[52,[30,5],\" pix-filterable-and-searchable-select--error\"]]]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@values\",\"@label\",\"@options\",\"@onChange\",\"@screenReaderOnly\",\"@className\"],[[30,0,[\"selectedCategories\"]],[30,6],[30,0,[\"categories\"]],[30,0,[\"selectCategories\"]],true,\"pix-filterable-and-searchable-select__pix-multi-select\"]],[[\"placeholder\",\"default\"],[[[[1,[30,0,[\"categoriesPlaceholder\"]]]],[]],[[[1,[30,7,[\"label\"]]]],[7]]]]],[1,\"\\n    \"],[8,[39,2],null,[[\"@id\",\"@placeholder\",\"@value\",\"@options\",\"@onChange\",\"@isSearchable\",\"@searchLabel\",\"@screenReaderOnly\",\"@className\"],[[30,0,[\"id\"]],[30,8],[30,9],[30,0,[\"selectableOptions\"]],[30,10],[30,11],[30,12],true,\"pix-filterable-and-searchable-select__pix-select\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[10,2],[14,0,\"pix-filterable-and-searchable-select__error-message\"],[12],[1,[30,5]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@screenReaderOnly\",\"@requiredText\",\"@label\",\"@subLabel\",\"@errorMessage\",\"@categoriesLabel\",\"option\",\"@placeholder\",\"@value\",\"@onChange\",\"@isSearchable\",\"@searchLabel\"],false,[\"if\",\"pix-multi-select\",\"pix-select\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-filterable-and-searchable-select.hbs",
    "isStrictMode": false
  });

  let PixFilterableAndSearchableSelect = (_class = class PixFilterableAndSearchableSelect extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "selectedCategories", _descriptor, this);

      _defineProperty(this, "id", 'pix-filterable-and-searchable-select' + (0, _internals.guidFor)(this));
    }

    selectCategories(categories) {
      this.selectedCategories = categories;
    }

    get categories() {
      const categoryNames = [];
      this.args.options.forEach(option => {
        if (!categoryNames.includes(option.category)) {
          categoryNames.push(option.category);
        }
      });
      return categoryNames.map(category => {
        return {
          label: category,
          value: category
        };
      });
    }

    get categoriesPlaceholder() {
      return `${this.args.categoriesPlaceholder} (${this.selectedCategories.length})`;
    }

    get selectableOptions() {
      const selectableOptions = [];
      const categories = this.selectedCategories.length === 0 ? this.categories.map(_ref => {
        let {
          value
        } = _ref;
        return value;
      }) : this.selectedCategories;
      this.args.options.forEach(option => {
        if (categories.includes(option.category)) {
          selectableOptions.push(option);
        }
      });
      return selectableOptions;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedCategories", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectCategories", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectCategories"), _class.prototype)), _class);
  _exports.default = PixFilterableAndSearchableSelect;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixFilterableAndSearchableSelect);
});