define("ember-dayjs/helpers/dayjs-start-of", ["exports", "ember-dayjs/helpers/base-helper"], function (_exports, _baseHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DayjsStartOf extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      return this.dayjs.self().startOf(params[0]);
    }

  }

  _exports.default = DayjsStartOf;
});