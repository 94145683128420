define("ember-dayjs/helpers/dayjs-duration-humanize", ["exports", "ember-dayjs/helpers/dayjs-duration"], function (_exports, _dayjsDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DayjsDurationHumanize extends _dayjsDuration.default {
    compute(params, hash) {
      this.dayjs.useLocale(hash.locale || this.dayjs.locale);
      this.dayjs.extend('relativeTime');
      return super.compute(params, hash).locale(hash.locale || this.dayjs.locale).humanize();
    }

  }

  _exports.default = DayjsDurationHumanize;
});