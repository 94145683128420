define("ember-dayjs/helpers/dayjs-is-leap-year", ["exports", "ember-dayjs/helpers/base-helper"], function (_exports, _baseHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DayjsIsLeapYear extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      this.dayjs.extend('isLeapYear');
      return this.dayjs.self(params[0]).isLeapYear();
    }

  }

  _exports.default = DayjsIsLeapYear;
});