define("ember-cli-notifications/components/notification-message", ["exports", "@ember/component", "@ember/template", "@ember/object", "@ember/service", "ember-cli-notifications/templates/components/notification-message"], function (_exports, _component, _template, _object, _service, _notificationMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const availableTypes = ['info', 'success', 'warning', 'error'];
  let NotificationMessage = (_dec = (0, _object.computed)('notification.dismiss'), _dec2 = (0, _object.computed)('notification.onClick'), _dec3 = (0, _object.computed)('notification.type'), _dec4 = (0, _object.computed)('validType'), _dec5 = (0, _object.computed)('validType'), _dec6 = (0, _object.computed)('validType'), _dec7 = (0, _object.computed)('validType'), _dec8 = (0, _object.computed)('paused', 'notification.clearDuration'), (_class = class NotificationMessage extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "layout", _notificationMessage.default);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _defineProperty(this, "paused", false);
    }

    get dismissClass() {
      return !this.notification.dismiss ? 'c-notification--in' : '';
    }

    get clickableClass() {
      return this.notification.onClick ? 'c-notification--clickable' : '';
    }

    get validType() {
      let type = this.notification.type;

      if (!type || availableTypes.indexOf(type) === -1) {
        return '';
      }

      return type;
    }

    get isInfo() {
      return this.validType === 'info';
    }

    get isSuccess() {
      return this.validType === 'success';
    }

    get isWarning() {
      return this.validType === 'warning';
    }

    get isError() {
      return this.validType === 'error';
    } // Apply the clear animation duration rule inline


    get notificationClearDuration() {
      const duration = Number.parseInt(this.notification.clearDuration, 10);
      const playState = this.paused ? 'paused' : 'running';
      const styles = [`animation-duration: ${duration}ms`, `animation-play-state: ${playState}`].join(';');
      return (0, _template.htmlSafe)(styles);
    }

    handleOnClick(event) {
      var _this$notification$on, _this$notification;

      event.preventDefault();
      (_this$notification$on = (_this$notification = this.notification).onClick) === null || _this$notification$on === void 0 ? void 0 : _this$notification$on.call(_this$notification, this.notification);
    }

    removeNotification(event) {
      event.preventDefault();
      event.stopPropagation();
      this.notifications.removeNotification(this.notification);
    }

    handleMouseEnter() {
      if (this.notification.autoClear) {
        (0, _object.set)(this, 'paused', true);
        this.notifications.pauseAutoClear(this.notification);
      }
    }

    handleMouseLeave() {
      if (this.notification.autoClear) {
        (0, _object.set)(this, 'paused', false);
        this.notifications.setupAutoClear(this.notification);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dismissClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "dismissClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickableClass", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "clickableClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validType", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "validType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInfo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSuccess", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isWarning", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isWarning"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isError", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "isError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "notificationClearDuration", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "notificationClearDuration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOnClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeNotification", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeNotification"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseEnter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseLeave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseLeave"), _class.prototype)), _class));
  _exports.default = NotificationMessage;
});