define("@1024pix/pix-ui/components/pix-radio-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-radio-button">
    <label class="{{if @isDisabled ' pix-radio-button--disabled'}}">
      <input
        disabled={{@isDisabled}}
        aria-disabled="{{@isDisabled}}"
        type="radio"
        value={{@value}}
        ...attributes
      />
      {{@label}}
    </label>
  </div>
  */
  {
    "id": "pzXieq83",
    "block": "[[[10,0],[14,0,\"pix-radio-button\"],[12],[1,\"\\n  \"],[10,\"label\"],[15,0,[29,[[52,[30,1],\" pix-radio-button--disabled\"]]]],[12],[1,\"\\n    \"],[11,\"input\"],[16,\"disabled\",[30,1]],[16,\"aria-disabled\",[29,[[30,1]]]],[24,4,\"radio\"],[16,2,[30,2]],[17,3],[12],[13],[1,\"\\n    \"],[1,[30,4]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isDisabled\",\"@value\",\"&attrs\",\"@label\"],false,[\"if\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-radio-button.hbs",
    "isStrictMode": false
  });

  class PixRadioButton extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "text", 'pix-radio-button');
    }

  }

  _exports.default = PixRadioButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixRadioButton);
});