define("@1024pix/pix-ui/components/pix-tag", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-tag {{this.classes}}" ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "PNLGEljG",
    "block": "[[[11,0],[16,0,[29,[\"pix-tag \",[30,0,[\"classes\"]]]]],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-tag.hbs",
    "isStrictMode": false
  });

  class PixTag extends _component2.default {
    get classes() {
      const {
        color,
        compact
      } = this.args;
      const classes = [];
      if (color) classes.push(`pix-tag--${color}`);
      if (compact) classes.push(`pix-tag--compact`);
      return classes.join(' ');
    }

  }

  _exports.default = PixTag;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixTag);
});