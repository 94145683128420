define("ember-dayjs/helpers/unix", ["exports", "ember-dayjs/helpers/dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Unix extends _dayjs.default {}

  _exports.default = Unix;
});