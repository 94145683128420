define("@1024pix/pix-ui/components/pix-icon-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    type="button"
    aria-label={{this.ariaLabel}}
    class="pix-icon-button pix-icon-button--{{this.size}}
      pix-icon-button--{{this.color}}
      {{if @withBackground ' pix-icon-button--background'}}"
    {{on "click" this.triggerAction}}
    ...attributes
  >
    <FaIcon @icon={{this.icon}} @prefix={{@iconPrefix}} />
  </button>
  */
  {
    "id": "jfy3Wnhd",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,\"aria-label\",[30,0,[\"ariaLabel\"]]],[16,0,[29,[\"pix-icon-button pix-icon-button--\",[30,0,[\"size\"]],\"\\n    pix-icon-button--\",[30,0,[\"color\"]],\"\\n    \",[52,[30,1],\" pix-icon-button--background\"]]]],[17,2],[4,[38,1],[\"click\",[30,0,[\"triggerAction\"]]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@icon\",\"@prefix\"],[[30,0,[\"icon\"]],[30,3]]],null],[1,\"\\n\"],[13]],[\"@withBackground\",\"&attrs\",\"@iconPrefix\"],false,[\"if\",\"on\",\"fa-icon\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-icon-button.hbs",
    "isStrictMode": false
  });

  let PixIconButton = (_class = class PixIconButton extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "text", 'pix-icon-button');
    }

    get icon() {
      const defaultIcon = 'plus';
      return this.args.icon ? this.args.icon : defaultIcon;
    }

    get size() {
      return this.args.size || 'big';
    }

    get color() {
      return this.args.color || 'light-grey';
    }

    get ariaLabel() {
      if (!this.args.ariaLabel || !this.args.ariaLabel.trim()) {
        throw new Error('ERROR in PixIconButton component, @ariaLabel param is not provided.');
      }

      return this.args.ariaLabel;
    }

    triggerAction(params) {
      if (this.args.triggerAction) {
        this.args.triggerAction(params);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "triggerAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerAction"), _class.prototype)), _class);
  _exports.default = PixIconButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixIconButton);
});