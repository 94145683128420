define("ember-dayjs/helpers/dayjs", ["exports", "ember-dayjs/helpers/base-helper", "@ember/utils"], function (_exports, _baseHelper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Dayjs extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      this.dayjs.useLocale(hash.locale || this.dayjs.locale);

      if ((0, _utils.typeOf)(params) === 'object') {
        this.dayjs.extend('objectSupport');
      }

      return this.dayjs.self(...params).locale(hash.locale || this.dayjs.locale);
    }

  }

  _exports.default = Dayjs;
});