define("@1024pix/pix-ui/components/pix-return-to", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo
    @route={{this.route}}
    @models={{if @model (array @model) this.defaultModel}}
    class="pix-return-to pix-return-to--{{this.shade}}"
    ...attributes
  >
  
    {{#if (has-block)}}
      <span aria-hidden="true" class="pix-return-to__icon"><FaIcon @icon="arrow-left" /></span>
      <span class="pix-return-to__text"> {{yield}} </span>
    {{else}}
      <span title="Flèche de retour" class="pix-return-to__icon"><FaIcon @icon="arrow-left" /></span>
    {{/if}}
  
  </LinkTo>
  */
  {
    "id": "c6039wiF",
    "block": "[[[8,[39,0],[[16,0,[29,[\"pix-return-to pix-return-to--\",[30,0,[\"shade\"]]]]],[17,1]],[[\"@route\",\"@models\"],[[30,0,[\"route\"]],[52,[30,2],[28,[37,2],[[30,2]],null],[30,0,[\"defaultModel\"]]]]],[[\"default\"],[[[[1,\"\\n\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[10,1],[14,\"aria-hidden\",\"true\"],[14,0,\"pix-return-to__icon\"],[12],[8,[39,4],null,[[\"@icon\"],[\"arrow-left\"]],null],[13],[1,\"\\n    \"],[10,1],[14,0,\"pix-return-to__text\"],[12],[1,\" \"],[18,3,null],[1,\" \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,\"title\",\"Flèche de retour\"],[14,0,\"pix-return-to__icon\"],[12],[8,[39,4],null,[[\"@icon\"],[\"arrow-left\"]],null],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@model\",\"&default\"],false,[\"link-to\",\"if\",\"array\",\"has-block\",\"fa-icon\",\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-return-to.hbs",
    "isStrictMode": false
  });

  class PixReturnTo extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "text", 'pix-return-to');

      _defineProperty(this, "availableShade", ['black', 'white', 'blue']);

      _defineProperty(this, "defaultModel", []);
    }

    get route() {
      const routeParam = this.args.route;

      if (routeParam == undefined || routeParam.trim() == '') {
        throw new Error('ERROR in PixReturnTo component, @route param is not provided');
      }

      return routeParam;
    }

    get shade() {
      const shadeParam = this.args.shade;
      return this.availableShade.includes(shadeParam) ? shadeParam : this.availableShade[0];
    }

  }

  _exports.default = PixReturnTo;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixReturnTo);
});