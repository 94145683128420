define("@1024pix/pix-ui/components/pix-button-base", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PixButtonBase extends _component.default {
    get shape() {
      return this.args.shape || 'squircle';
    }

    get backgroundColor() {
      return this.args.backgroundColor || 'blue';
    }

    get size() {
      return this.args.size || 'big';
    }

    get baseClassNames() {
      const classNames = ['pix-button', `pix-button--shape-${this.shape}`, `pix-button--size-${this.size}`, `pix-button--background-${this.backgroundColor}`];
      this.args.isBorderVisible && classNames.push('pix-button--border');
      this.args.isDisabled && classNames.push('pix-button--disabled');
      return classNames;
    }

  }

  _exports.default = PixButtonBase;
});