define("@1024pix/pix-ui/components/pix-stars", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.pixStarsClass}} ...attributes aria-label={{@alt}}>
    {{#each this.stars as |star|}}
      <svg
        class="pix-stars__{{star}}"
        data-test-status={{star}}
        viewBox="0 0 36 36"
        role="img"
        aria-hidden="true"
      >
        <defs>
          <linearGradient id="pix-stars-default" x1="68.643%" y1="0%" x2="68.643%" y2="100%">
            <stop stop-color="#FEDC41" offset="0%"></stop>
            <stop stop-color="#FF9F00" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path
          d="M8.423 35.82c-.761.507-1.765-.125-1.635-1.03l1.619-11.335L.311 15.36a1.059 1.059 0 01.6-1.796l11.268-1.61L17.027.642c.367-.856 1.58-.856 1.946 0l4.848 11.31 11.269 1.61a1.059 1.059 0 01.599 1.797l-8.096 8.096 1.62 11.334c.129.906-.875 1.538-1.636 1.03L18 29.436 8.423 35.82z"
        ></path>
      </svg>
    {{/each}}
  </div>
  */
  {
    "id": "+mdw7DXP",
    "block": "[[[11,0],[16,0,[30,0,[\"pixStarsClass\"]]],[17,1],[16,\"aria-label\",[30,2]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"stars\"]]],null]],null],null,[[[1,\"    \"],[10,\"svg\"],[15,0,[29,[\"pix-stars__\",[30,3]]]],[15,\"data-test-status\",[30,3]],[14,\"viewBox\",\"0 0 36 36\"],[14,\"role\",\"img\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n      \"],[10,\"defs\"],[12],[1,\"\\n        \"],[10,\"linearGradient\"],[14,1,\"pix-stars-default\"],[14,\"x1\",\"68.643%\"],[14,\"y1\",\"0%\"],[14,\"x2\",\"68.643%\"],[14,\"y2\",\"100%\"],[12],[1,\"\\n          \"],[10,\"stop\"],[14,\"stop-color\",\"#FEDC41\"],[14,\"offset\",\"0%\"],[12],[13],[1,\"\\n          \"],[10,\"stop\"],[14,\"stop-color\",\"#FF9F00\"],[14,\"offset\",\"100%\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"path\"],[14,\"d\",\"M8.423 35.82c-.761.507-1.765-.125-1.635-1.03l1.619-11.335L.311 15.36a1.059 1.059 0 01.6-1.796l11.268-1.61L17.027.642c.367-.856 1.58-.856 1.946 0l4.848 11.31 11.269 1.61a1.059 1.059 0 01.599 1.797l-8.096 8.096 1.62 11.334c.129.906-.875 1.538-1.636 1.03L18 29.436 8.423 35.82z\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[3]],null],[13]],[\"&attrs\",\"@alt\",\"star\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-stars.hbs",
    "isStrictMode": false
  });

  const STAR_ACQUIRED = 'acquired';
  const STAR_UNACQUIRED = 'unacquired';

  class PixStars extends _component2.default {
    get pixStarsClass() {
      if (!this.args.color) return 'pix-stars';
      return `pix-stars pix-stars--${this.args.color}`;
    }

    get stars() {
      const {
        count = 0,
        total = 0
      } = this.args;
      const starsTotal = total || count;
      const stars = [];

      for (let index = 0; index < starsTotal; index++) {
        if (index < count) {
          stars[index] = STAR_ACQUIRED;
        } else {
          stars[index] = STAR_UNACQUIRED;
        }
      }

      return stars;
    }

  }

  _exports.default = PixStars;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixStars);
});