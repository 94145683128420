define("ember-intl/helpers/-format-base", ["exports", "@ember/component/helper", "@ember/application", "@ember/utils"], function (_exports, _helper, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  /**
   * @private
   * @hide
   */
  const AbstractHelper = _helper.default.extend({
    intl: null,

    init() {
      if (this.constructor === AbstractHelper) {
        throw new Error('FormatHelper is an abstract class, can not be instantiated directly.');
      }

      this._super();

      this.intl = (0, _application.getOwner)(this).lookup('service:intl');
      this.intl.on('localeChanged', this, 'recompute');
    },

    format() {
      throw new Error('not implemented');
    },

    compute(_ref, options) {
      let [value] = _ref;

      if ((0, _utils.isEmpty)(value)) {
        var _options$allowEmpty;

        if ((_options$allowEmpty = options.allowEmpty) !== null && _options$allowEmpty !== void 0 ? _options$allowEmpty : this.allowEmpty) {
          return;
        }

        if (typeof value === 'undefined') {
          throw new Error(`${this} helper requires value attribute.`);
        }
      }

      return this.format(value, options);
    },

    willDestroy() {
      this._super();

      this.intl.off('localeChanged', this, 'recompute');
    }

  });

  var _default = AbstractHelper;
  _exports.default = _default;
});