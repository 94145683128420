define("ember-dayjs/helpers/dayjs-diff", ["exports", "ember-dayjs/helpers/base-helper"], function (_exports, _baseHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DayjsDiff extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      return this.dayjs.self(params[0]).diff(params[1], hash.precision, hash.float || false);
    }

  }

  _exports.default = DayjsDiff;
});